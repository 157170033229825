.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;margin-top:100px}
@media (min-width:map-get($breakpoints, small)) {
	.container{max-width:100%}
}
@media (min-width:map-get($breakpoints, medium)) {
	.container{max-width:100%;margin-top:0;}
}
@media (min-width:map-get($breakpoints, large)) {
	.container{max-width:100%}
}
@media (min-width:map-get($breakpoints, extralarge)) {
	.container{max-width:100%}
}

/* Colunas */
.container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto}
.row{display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;margin-right:-15px;margin-left:-15px}
.no-gutters{margin-right:0;margin-left:0}
.no-gutters>.col,.no-gutters>[class*=col-]{padding-right:0;padding-left:0}
.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto{position:relative;width:100%;min-height:1px;padding-right:15px;padding-left:15px}
.col{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;flex-grow:1;max-width:100%}
.col-auto{-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
.col-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
.col-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
.col-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
.col-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
.col-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
.col-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
.col-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
.col-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
.col-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
.col-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
.col-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
.col-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
.order-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
.order-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
.order-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
.order-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
@media (min-width:map-get($breakpoints, small)) {
	.col-sm{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;flex-grow:1;max-width:100%}
	.col-sm-auto{-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
	.col-sm-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
	.col-sm-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
	.col-sm-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
	.col-sm-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
	.col-sm-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
	.col-sm-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
	.col-sm-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
	.col-sm-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
	.col-sm-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
	.col-sm-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
	.col-sm-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
	.col-sm-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
	.order-sm-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
	.order-sm-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
	.order-sm-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
	.order-sm-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
}
@media (min-width:map-get($breakpoints, medium)) {
	.col-md{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;flex-grow:1;max-width:100%}
	.col-md-auto{-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
	.col-md-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
	.col-md-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
	.col-md-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
	.col-md-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
	.col-md-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
	.col-md-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
	.col-md-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
	.col-md-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
	.col-md-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
	.col-md-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
	.col-md-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
	.col-md-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
	.order-md-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
	.order-md-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
	.order-md-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
	.order-md-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
}
@media (min-width:map-get($breakpoints, large)) {
	.col-lg{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;flex-grow:1;max-width:100%}
	.col-lg-auto{-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
	.col-lg-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
	.col-lg-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
	.col-lg-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
	.col-lg-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
	.col-lg-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
	.col-lg-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
	.col-lg-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
	.col-lg-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
	.col-lg-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
	.col-lg-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
	.col-lg-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
	.col-lg-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
	.order-lg-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
	.order-lg-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
	.order-lg-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
	.order-lg-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
}
@media (min-width:map-get($breakpoints, extralarge)) {
	.col-xl{-ms-flex-preferred-size:0;flex-basis:0;-ms-flex-positive:1;flex-grow:1;max-width:100%}
	.col-xl-auto{-ms-flex:0 0 auto;flex:0 0 auto;width:auto;max-width:none}
	.col-xl-1{-ms-flex:0 0 8.333333%;flex:0 0 8.333333%;max-width:8.333333%}
	.col-xl-2{-ms-flex:0 0 16.666667%;flex:0 0 16.666667%;max-width:16.666667%}
	.col-xl-3{-ms-flex:0 0 25%;flex:0 0 25%;max-width:25%}
	.col-xl-4{-ms-flex:0 0 33.333333%;flex:0 0 33.333333%;max-width:33.333333%}
	.col-xl-5{-ms-flex:0 0 41.666667%;flex:0 0 41.666667%;max-width:41.666667%}
	.col-xl-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%}
	.col-xl-7{-ms-flex:0 0 58.333333%;flex:0 0 58.333333%;max-width:58.333333%}
	.col-xl-8{-ms-flex:0 0 66.666667%;flex:0 0 66.666667%;max-width:66.666667%}
	.col-xl-9{-ms-flex:0 0 75%;flex:0 0 75%;max-width:75%}
	.col-xl-10{-ms-flex:0 0 83.333333%;flex:0 0 83.333333%;max-width:83.333333%}
	.col-xl-11{-ms-flex:0 0 91.666667%;flex:0 0 91.666667%;max-width:91.666667%}
	.col-xl-12{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%}
	.order-xl-0{-webkit-box-ordinal-group:1;-ms-flex-order:0;order:0}
	.order-xl-1{-webkit-box-ordinal-group:2;-ms-flex-order:1;order:1}
	.order-xl-2{-webkit-box-ordinal-group:3;-ms-flex-order:2;order:2}
	.order-xl-3{-webkit-box-ordinal-group:4;-ms-flex-order:3;order:3}
}
$blue: #224EE5;
$grey: #DFE3E8;

.list-container .user-list .box-text .perfil-list.addons .perfil-item {
  background-color: #fff;
}

.timeline {
  width: 100%;
  position: relative;
  height: 2px;
  margin: 16px 0 32px;
  background-color: $grey;

  &.half::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 50%;
    background-color: $blue;
    transform: translate3d(0, 0, 0);
  }

  &.complete::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: #08be2f;
    transform: translate3d(0, 0, 0);
  }

  .timeline-dot {
    position: absolute;
    top: 50%;
    width: 8px;
    height: 8px;
    background-color: #afb4b9;
    transform: translate3d(0, -50%, 0);
    border-radius: 50%;

    &.active {
      width: 16px;
      height: 16px;
      background-color: $blue;

      &:nth-of-type(3) {
        background-color: #08be2f;
      }
    }

    &:nth-of-type(2) {
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    &:nth-of-type(3) {
      right: 0;
    }
  }
}

.receive-finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  p {
    padding: 16px;
    font-size: 12px;
    text-align: center;
  }

  button {
    margin-bottom: 16px;
  }
}

.list-container {
  .box-text {
    position: relative;

    .box-message{
      border-radius: 3px;
      color: #fff;
      font-size: 10px;
      text-transform: uppercase;
      top: 0;
      right: 0;
      padding: 4px 8px;

      &.sub-modification {
        position: absolute;
        
        
  
        &.new {
          background-color: $blue;
        }
  
        &.edited {
          background-color: #d3a50d;
        }
  
        &.buying {
          background-color: #55e450;
        }
  
        &.unavailable {
          background-color: #98a0aa;
        }
      }

      &.scheduled{
        background-color: #d3a50d;
        margin-left: 10px;
      }
    }

    .btn-rateio {
      padding: 5px;
      margin-left: 8px;
    }

    .empty-profiles .btn-rateio,
    .perfil-list .btn-rateio {
      font-size: 10px;
    }

    .perfil-list .btn-rateio {
      margin-top: 10px;
    }

    input {
      border: 1px solid $grey;
      border-radius: 4px;
      line-height: 16px;
      width: 56px;
      padding: 4px;
      background: 0 0;
      outline: none;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      -ms-appearance: none;
      -o-appearance: none;
    
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    
      &:read-only {
        color: #999;
        cursor: not-allowed;
      }
    }

    .empresa-rateio {
      .empresa-rateio-wrapper {
        margin: 16px 0 0;
        padding: 0 0 8px;
        border-bottom: 1px solid $grey;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:last-child {
          border-bottom: none;
        }

        .empresa-rateio-name span {
          font-size: 10px;
        }

        .rateio {
          display: flex;
          align-items: center;

          span {
            margin-right: 16px;
          }
        }
      }
    }

    .box-validity-frequency{
      display: flex;
      justify-content: flex-start;

      .validity{
        margin-right: 50px;
      }
    }
  }
}

.csp-actions {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;

  #btnEditar {
    margin-left: 16px;
  }
}

.checkout-list {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  z-index: 2;

  thead {
    background-color: #5c6dce;
    color: #fff;
  }

  tbody {
    tr {
      th {
        font-weight: normal;
      }

      &:nth-of-type(even) {
        background-color: #f7f8ff;
      }
    }
  }

  th {
    text-align: right;
    padding: 12px 16px;

    &.align-left {
      text-align: left;

      &.sub-item {
        padding-left: 40px;
      }
    }
  }
}

.checkout-options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  button {
    padding: 8px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    border-radius: 4px 4px 0 0;
    background-color: #fff;
    color: #5c6dce;
    cursor: pointer;
    z-index: 0;

    &.active {
      background-color: #5c6dce;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, .1);
      color: #fff;
      z-index: 1;
    }
  }
}

.modal.csp-modal {
  .modal-content {
    max-width: 700px;
    
    h1 {
      padding: 0;
      margin-top: 0;
      border-bottom: none;
    }
  
    .csp-modal-wrapper {
      padding: 0 32px 32px;
    }
  
    .list-header {
      padding: 0 0 16px;
  
      .filter-box {
        flex: 1;
        margin-right: 16px;
      }
    }
  
    .licenca-selecionada {
      margin-bottom: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
  
      div {
        display: flex;
        flex-direction: column;
  
        .title {
          color: #000;
        }
      }
    }
  
    .list-header {
      padding-bottom: 0;
      margin-bottom: 32px;
      border: none;
    }
  
    .licencas-quantidade {
      height: 340px;
      max-height: 340px;
      padding-right: 16px;
      margin-top: 32px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;    
  
      .licenca {
        padding: 8px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid $grey;
  
        &:nth-of-type(1) {
          border-top: none;
        }
  
        .licenca-name {
          display: flex;
          flex-direction: column;

          span {
            color: #224EE5;
          }
        }
  
        .quantidade {
          span {
            margin-right: 16px;
          }
  
          input {
            border: 1px solid $grey;
            border-radius: 4px;
            line-height: 16px;
            width: 56px;
            padding: 10px;
            background: 0 0;
            outline: none;
            text-align: center;
            -webkit-appearance: none;
            -moz-appearance: textfield;
            -ms-appearance: none;
            -o-appearance: none;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            &:read-only {
              color: #999;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  
    .actions {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
    }
  
    #picky-list {
      max-height: 280px;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, .15);
    }
  }

  .loading-profiles {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value-change {
    margin-top: 16px;
  }
}

.mensal-price {
  padding: 16px;
  display: flex;
  justify-content: flex-end;
}

.sem-assinatura {
  width: 100%;
  display: block;
  text-align: center;
  padding: 16px 0;
}

.small-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  .simple-loading {
    width: 30px;
    height: 30px;

    &::before {
      width: 30px;
      height: 30px;
    }
  }
}

.inputError{
  border: 1px solid #e94a44 !important;
  background: #e94a442e !important;
}
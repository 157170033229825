.notification-container {

    .notification {
        opacity: 1;
        box-shadow: none
    }

    .notification-success {
        background-color: $not-success;
    }
    
    .notification-error {
        background-color: $not-error;
    }
    
    .notification-message h4 {
        margin: 0;
    }
}
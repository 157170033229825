.list-container .list-chamados {
	& > .list-box {
		border-left: 5px solid #dfe3e8;
		cursor: pointer;
		padding: 15px;

		&.aguardando-atendimento {
			border-left-color: $brand-color;

			.status {
				color: $brand-color;
			}
		}

		&.em-atendimento {
			border-left-color: $brand-color;

			.status {
				color: $brand-color;
			}
		}

		&.aguardando-cliente {
			border-left-color: #e5a122;

			.status {
				color: #e5a122;
			}
		}

		&.suspenso {
			.status {
				color: #ef8e8e;
			}
		}

		&.agendado {
			.status {
				color: #ef8e8e;
			}
		}

		&.cancelado {
			.status {
				color: $backround-erro-clear;
			}
		}

		&.mesclado {
			.status {
				color: $backround-erro-clear;
			}
		}

		&.resolvido {
			.status {
				color: #5cb975;
			}
		}

		&.informacoes-fornecidas {
			.status {
				color: #5cb975;
			}
		}

		&.aceito-pelo-cliente {
			.status {
				color: #5cb975;
			}
		}

		.header {
			display: table;
			font-size: 12px;
			margin-bottom: 10px;

			.id-type {
				color: #9e9e9e;
				font-size: 12px;
				display: block;
				margin-bottom: 5px;
			}

			.status {
				display: inline-block;
				margin: 0 20px 5px 0;
			}

			.atendente {
				display: inline-block;
				color: #9e9e9e;

				.icon {
					margin-right: 5px;
				}
			}
		}

		.info {
			margin-top: 10px;
			font-size: 12px;
			color: #9e9e9e;

			.user-owner {
				display: block;

				.icon {
					margin-right: 5px;
				}
			}

			.date-container {
				display: block;
				margin-top: 10px;

				.date {
					color: #212b35;
				}
			}
		}
	}
}

.form-group-chamado {
	background: #f1f1f1;
	padding: 10px;
	margin-bottom: 15px;

	.form-label {
		display: block;
	}

	.form-text {
		display: block;
		color: #9e9e9e;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
        
        .icon{
            margin-right: 5px;
        }
	}
}

@media (min-width: map-get($breakpoints, large)) {
	.list-container .list-chamados {
		& > .list-box {
			.header {
				margin-bottom: 5px;

				.id-type {
					display: inline-block;
					margin: 0 20px 0 0;
				}
			}

			.title {
				font-size: 18px;
			}

			.info {
				.user-owner {
					display: inline-block;
					margin-right: 40px;
				}

				.date-container {
					display: inline-block;
					margin: 0 20px 0 0;
				}
			}
		}
	}
}

.ebook {
  width: 100%;
  height: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border: 1px solid #DFE3E5;
  border-radius: 8px;
}

.ebook-header {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    height: 22px;
  }
}

.ebook-body {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  height: 100%;
  box-sizing: border-box;

  img {
    width: 100%;
    height: auto;
  }
}

.ebook-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;

  h3 {
    margin-top: 0;
    color: $newDarkBlue;
    font-size: 22px;
    line-height: 26px;
    font-weight: lighter;
  }

  p {
    line-height: 1.5;
  }

  a {
    display: inline-block;
    padding: 6px 64px;
    border: 1px solid #DFE3E5;
    border-radius: 8px;
    color: $newBlue;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      background-color: $newBlue;
      color: #fff;
      text-decoration: none;
    }
  }
}

@media (min-width: map-get($breakpoints, medium)) {
  .ebook-body {
    grid-template-columns: .8fr 1.2fr;
  }
}


.modal .modal-content .header{
  padding: 0 !important;
}

.cancelarcontrato-modal {
  padding: 25px;
  height: 90vh;
  max-height: 90vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .cancelarcontrato-modal-policy{
    overflow: auto;
    border-style: inset;
    padding: 15px;

    .cancelarcontrato-modal-wrapper{
      padding-right: 16px;
    }
  }
  
  .cancelarcontrato-modal-footer{
    padding-top: 20px;
    max-height: 50%;

    .cancelarcontrato-modal-footer-check{
      font-size: 18px;
      letter-spacing: 0px;
      color: #193A70;
      padding: 25px 0;

      .checked-Policy{
        margin-right: 10px;
      }
    }
  }
}

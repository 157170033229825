.validator-container .validator {
    display: block;
    font-size: 12px;
    color: $valid_background;

    & + .validator{
        margin-top: 5px;
    }

    &.invalid {
        color: $label-icon-grey;
    }

    &:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $valid_background;
        margin-right: 10px;
    }

    &.invalid:before {
        background: $separator;
    }
    
}
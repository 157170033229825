.authenticated .content{
	padding: 0 0 0;
}

@media (min-width:map-get($breakpoints, medium)) {
	.authenticated .content{
		padding-top:0;
		padding-bottom:0;

		.col-12.col-md-4.col-lg-3 {
			padding: 0;
		}
	}
}

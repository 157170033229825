
.label-info{
  font-size: 12px;
}

.Dropzone {
  height: 50px;
  margin-top: 5px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;

    .Icon {
      opacity: 0.3;
      height: 64px;
      width: 64px;
    }

    .FileInput {
      width: 130px;
      height: 130px;
    }

  .card {
      background-color: white;
      padding: 64px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
      box-sizing: border-box;
  }

  .App {
      text-align: center;
      background-color: rgb(206, 213, 223);
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
    }

  &.drag-active {
      border-color: #03bd66;
  }

  &.drag-reject {
      border-color: #fc5c5c;
  }

  &.on-drag {
    border-color: #4052b5;
    color:#4052b5;
  }
}

.Dropzone:hover {
  border-color: #4052b5;
  color:#4052b5;
}






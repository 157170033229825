// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.aling-right-loading-buttons {
	margin-right: 30px;
}

.loading-container{
	position:relative;
	align-items: center;
	display: flex;
   flex-direction: row;
   flex-wrap: wrap;
	justify-content: center;
	

	i {
		width: 30px;
		margin: 0 auto;
	  } 
	  
	  .simple-loading {
		position: absolute;
		padding: 5px;
		bottom: 5px;
  
			 &:before {
				  width: 16px;
				  height: 16px;
				  border-width: 3px;
			 }
		}
}


@media (max-width:992px) {
	.container-help {
		position: inherit !important;

		a {
			margin-right: 15px;
		}
	}
}

.title-with-action {
	position: relative;

	.filter-select {
		height: 39px !important; 
		width: 495px;
		font-size: 18px;
		padding-top: 5px;
		padding-bottom: 0px; 
		padding-left: 10px;
		margin-top: 0px;
	}

	@media (max-width:992px) {
		.filter-select{
			width: 100%;
			font-size: 13px;
		}
	}


	.icon-control {
		position: absolute;
		bottom:11px;
		padding-left: 50%;
	}

	.filter-select option {
		font-size: 14px;
		text-align: justify !important;
	}

    .btn {
        position: absolute;
        right: 0;
        top: 0;
    }
}





.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

/* Display */
.d-none{display:none!important}
.d-inline{display:inline!important}
.d-inline-block{display:inline-block!important}
.d-block{display:block!important}
.d-table{display:table!important}
.d-table-row{display:table-row!important}
.d-table-cell{display:table-cell!important}
.d-flex{display:-ms-flexbox!important;display:flex!important}
.d-inline-flex{display:-ms-inline-flexbox!important;display:inline-flex!important}
@media (min-width:map-get($breakpoints, small)) {
	.d-sm-none{display:none!important}
	.d-sm-inline{display:inline!important}
	.d-sm-inline-block{display:inline-block!important}
	.d-sm-block{display:block!important}
	.d-sm-table{display:table!important}
	.d-sm-table-row{display:table-row!important}
	.d-sm-table-cell{display:table-cell!important}
	.d-sm-flex{display:-ms-flexbox!important;display:flex!important}
	.d-sm-inline-flex{display:-ms-inline-flexbox!important;display:inline-flex!important}
}
@media (min-width:map-get($breakpoints, medium)) {
	.d-md-none{display:none!important}
	.d-md-inline{display:inline!important}
	.d-md-inline-block{display:inline-block!important}
	.d-md-block{display:block!important}
	.d-md-table{display:table!important}
	.d-md-table-row{display:table-row!important}
	.d-md-table-cell{display:table-cell!important}
	.d-md-flex{display:-ms-flexbox!important;display:flex!important}
	.d-md-inline-flex{display:-ms-inline-flexbox!important;display:inline-flex!important}
}
@media (min-width:map-get($breakpoints, large)) {
	.d-lg-inline{display:inline!important}
	.d-lg-inline-block{display:inline-block!important}
	.d-lg-block{display:block!important}
	.d-lg-table{display:table!important}
	.d-lg-table-row{display:table-row!important}
	.d-lg-table-cell{display:table-cell!important}
	.d-lg-flex{display:-ms-flexbox!important;display:flex!important}
	.d-lg-inline-flex{display:-ms-inline-flexbox!important;display:inline-flex!important}
}
@media (min-width:map-get($breakpoints, extralarge)) {
	.d-lg-none{display:none!important}
	.d-xl-none{display:none!important}
	.d-xl-inline{display:inline!important}
	.d-xl-inline-block{display:inline-block!important}
	.d-xl-block{display:block!important}
	.d-xl-table{display:table!important}
	.d-xl-table-row{display:table-row!important}
	.d-xl-table-cell{display:table-cell!important}
	.d-xl-flex{display:-ms-flexbox!important;display:flex!important}
	.d-xl-inline-flex{display:-ms-inline-flexbox!important;display:inline-flex!important}
}
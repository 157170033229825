.content-box.form-container {
	padding-bottom: 0;
}

@media (min-width: map-get($breakpoints, large)) {
	.content-box.form-container {
		padding-bottom: 10px;
	}
}

.form-group {
	padding-bottom: 15px;

	label {
		display: table;
	}

	.form-group select.input-group {
		height: 38px;
	}

	.input-group {
		border: 1px solid $separator;
		display: block;
		border-radius: 3px;
		padding: 10px;
		margin-top: 5px;
		width: 100%;
		background: 0 0;
		outline: none;
		line-height: 1.4;
		max-width: 100%;
	}

	.input-group[readonly],
	.input-group[disabled] {
		background: $color-light-grey;
		border-color: $border-color;
	}

	input.input-group,
	select.input-group {
		height: 38px;
	}

	textarea.input-group {
		min-height: 150px;
		min-width: 100%;
	}

	&.form-error {
		label,
		.error {
			color: $danger;
		}

		.input-group {
			border-color: $light-pink;
		}

		.error {
			font-size: 12px;
			display: block;
			margin-top: 2px;
		}
	}

	&.form-group-checkbox{
		height: 80px;
		display: flex;
		align-items: flex-end;

		.checkbox {
			padding: 8px 0;
		}
	}
}

.checkbox {
	cursor: pointer;
	user-select: none;

	.check {
		display: none;

		& + .custom-check:before {
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			background: transparent;
			transition: 0.1s;
		}

		&:checked + .custom-check:before {
			background: #4b5fbb;
		}

		&:disabled + .custom-check {
            background: #f1f1f1;
            cursor: no-drop;
		}
	}
	.custom-check {
		display: inline-block;
		width: 16px;
		height: 16px;
		background: #fff;
		border: 1px solid #d2d2d2;
		margin-right: 10px;
		position: relative;
		top: 2px;
		padding: 3px;
	}
}

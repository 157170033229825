.filter-select-box {
    position: relative;
    

    .simple-loading {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        top: 0;
        left: 0;
        right: 20px;
        bottom: 0;

        &:before {
            width: 20px;
            height: 20px;
            border-width: 3px;
        }
    }

    @media (min-width:993px) {
        .simple-loading {
            max-width: 60%;
        }
    }
}
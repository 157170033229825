.pesquisa-satisfacao {
  margin-top: 50px;
  min-height: calc(100vh - 50px);
  .ant-form-item-label {
    text-align: center;
    font-weight: 700;
  }

  .ant-radio-button-wrapper,
  .ant-radio-wrapper {
    font-size: 16px;
  }


  .ant-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 64px;
    }

    h2 {
      margin: 0;
      color: #213973;
      font-weight: 800;
      text-align: center;
      font-size: 24px;
    }

    h3 {
      margin: 0;
      color: #213973;
      font-size: 20px;
      text-align: center;
    }
    .ant-form-item-label>label {
      font-size: 20px;
    }
    .ant-form-item-control-input{
      width: 100%;
    }
  }
}

.modal{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    padding: 15px;
   
    &.open{
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }

    .shadow-box{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        background-color: rgba(0, 0, 0, .7);
    }
    
    .modal-content{
        width: 100%;
        max-width: 500px;
        position: relative;
        background: $white;
        .header{
            display: flex;
            justify-content: flex-end;
            padding: 20px;

            .icon{
                color: #DFE3E8;
                cursor: pointer;
            }
        }
        
        .text-modal{
            text-align: center;
            padding: 20px 15px 0;
            color: #212B36;
            font-size: 18px;
        }

        .text-modal-justify {
            text-align: justify;
            padding: 20px 30px 0;
            color: #212B36;
            font-size: 18px;
        }

        .text-modal-left {
            text-align: left;
            padding: 20px 15px 0;
            color: #212B36;
            font-size: 18px;
        }

        .content-modal{
            padding: 15px;
        }
        
        .btn-modal{
            margin-top: 50px;
            border-top: 1px solid;
            padding: 20px;
            color: rgb(225, 225, 228); 
            display: flex;
            justify-content: flex-end;   
        }       
        
        .btn+.btn{
            margin-left: 10px;
        }
    }

    .modal-content-xl {
        max-width: 90vw;
    }

    .modal-content-lg {
        max-width: 800px;
    }

    .modal-content-sm {
        max-width: 300px;
    }
}

.new-password {
    display: table;
    margin: 20px auto 0;
    padding: 4px 10px;
    background: #f1f1f1;
}

/* Success icon animated */
.animate-success-icon{position:relative;justify-content:center;width:5em;height:5em;margin:0 auto 30px;border:.25em solid transparent;border-radius:50%;line-height:5em;cursor:default;box-sizing:content-box;user-select:none;zoom:normal;border-color:#a5dc86;display:flex}
.animate-success-icon .success-circular-line-left,.animate-success-icon .success-circular-line-right{position:absolute;width:3.75em;height:7.5em;transform:rotate(45deg);border-radius:50%;background-color:$white}
.animate-success-icon .success-circular-line-left{top:-.4375em;left:-2.0635em;transform:rotate(-45deg);transform-origin:3.75em 3.75em;border-radius:7.5em 0 0 7.5em}
.animate-success-icon .success-circular-line-right{top:-.6875em;left:1.875em;transform:rotate(-45deg);transform-origin:0 3.75em;border-radius:0 7.5em 7.5em 0;animation:rotate-success-circular-line 4.25s ease-in}
.animate-success-icon .success-line-tip,.animate-success-icon .success-line-long{display:block;position:absolute;height:.3125em;border-radius:.125em;background-color:#a5dc86;z-index:2}
.animate-success-icon .success-line-tip{top:2.875em;left:.875em;width:1.5625em;transform:rotate(45deg);animation:animate-success-line-tip .75s}
.animate-success-icon .success-line-long{top:2.375em;right:.5em;width:2.9375em;transform:rotate(-45deg);animation:animate-success-line-long .75s}
.animate-success-icon .success-ring{position:absolute;top:-.25em;left:-.25em;width:100%;height:100%;border:.25em solid rgba(165,220,134,.3);border-radius:50%;z-index:2;box-sizing:content-box}
.animate-success-icon .success-fix{position:absolute;top:.5em;left:1.625em;width:.4375em;height:5.625em;transform:rotate(-45deg);z-index:1;background-color:$white}
@keyframes animate-success-line-tip {
    0%{top:1.1875em;left:.0625em;width:0}
    54%{top:1.0625em;left:.125em;width:0}
    70%{top:2.1875em;left:-.375em;width:3.125em}
    84%{top:3em;left:1.3125em;width:1.0625em}
    100%{top:2.8125em;left:.875em;width:1.5625em}
}
@keyframes animate-success-line-long {
    0%{top:3.375em;right:2.875em;width:0}
    65%{top:3.375em;right:2.875em;width:0}
    84%{top:2.1875em;right:0;width:3.4375em}
    100%{top:2.375em;right:.5em;width:2.9375em}
}
@keyframes rotate-success-circular-line {
    0%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
    5%{-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
    12%{-webkit-transform:rotate(-405deg);transform:rotate(-405deg)}
    100%{-webkit-transform:rotate(-405deg);transform:rotate(-405deg)}
}
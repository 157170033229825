.list-container{
    padding: 0;
    
    .list-header{
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid $separator;

        .filter-box{
            position: relative;
            color: $text-color;
            order: 2;
            margin-top: 10px;
            width: 100%;
            display: flex;

            .search-user {
                cursor: pointer;
            }

            .filter-option{
                width: 100%;
                
                .filter {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                .date-filter-container {
                    border: 1px solid $separator;
                    border-radius: 4px 0 0 4px;
                    display: flex;
                    position: relative;
                    padding: 5px 7px;
                    height: 38px;
                    align-items: center;
                    
                    .date-filter{
                        display: flex;
                        width: 100%;
                        
                        &+.date-filter {
                            margin-left: 5px;
                        }
                        
                        label {
                            font-size: 12px;
                            color: $label-icon-grey;
                            display: block;
                            margin-right: 2px;
                        }
                        
                        .date {
                            width: 100%;
                            border: 0;
                            padding: 0;
                            height: auto;
                            font-size: 12px;
                            outline: none;
                            border-bottom: 1px solid $color-light-grey
                        }
                    }
                    
                    .icon{
                        display: none
                    }
                }
            }
            
            .filter{
                border: 1px solid $separator;
                border-radius: 4px;
                line-height: 16px;
                width:100%;
                padding: 10px 10px 10px 40px;
                background: 0 0;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                -o-appearance: none;
                appearance: none;
            }

            .filter-select-box{
                position: relative;

                .filter{
                    padding-right: 30px;
                }
                
                .simple-loading {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: $simple-loading;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;

                    &:before {
                        width: 16px;
                        height: 16px;
                        border-width: 3px;
                    }
                }
                
                .icon{
                    position: absolute;
                    right: 10px;
                    left: auto;
                    font-size: 7px;
                    pointer-events: none;
                }
            }

            .icon{
                position: absolute;
                left: 15px;
                top:0;
                bottom: 0;
                display: flex;
                align-items: center;
            }

            .filter-options-container {
                background: $separator;
                position: relative;
                border-radius: 0 4px 4px 0;
                
                .filter-select-option {
                    display: block;
                    border: 0;
                    background: 0 0;
                    padding: 10px 14px 10px 5px;
                    white-space: nowrap;
                    font-size: 12px;
                    color: $featured-text;
                    outline: none;
                    height: 100%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    -o-appearance: none;
                    appearance: none;
                }
        
                .icon{
                    right: 7px;
                    left: auto;
                    top: 0;
                    bottom: 0;
                    font-size: 7px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    pointer-events: none;
                    width: auto;
                }
            }
        }
        
        .order-box{
            flex: 0 0 auto;
            font-size: 12px;
            align-items: center;
            order: 1;

            .order-label{
                display: block;
                white-space: nowrap;
            }

            .select-order{
                position: relative;

                select{
                    border: 0;
                    padding: 0 15px 0 0;
                    margin: 0;
                    background:0 0;
                    outline: none;
                    width:100%;
                    @include appearance;
                }

                .icon{
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    font-size: 7px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    pointer-events: none;
                }
            }
        }
    }

    .list-selectable{
        &.list-selectable{
            .list-box{ 
                cursor: pointer;

                .check-container{
                    padding-right: 25px
                }
            }
        }
    }

    .list-items{
        position: relative;

        .load-more {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(#ffffff00 0%, #fff 60%, #fff 100%);
            padding: 45px 0 20px;
            text-align: center;
            font-size: 12px;
            color: #445EE9;
            cursor: pointer;
        }
    }

    .list-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px;
        border-bottom: 1px solid $separator;
        line-height: 1.2;

        &.list-loading{
            .box-text{
                .title {
                    display: block;
                    background: $color-light-grey;
                    height: 16px;
                    width: 160px;
                }
                
                .subtitle {
                    display: block;
                    background: $color-light-grey;
                    height: 12px;
                    width: 85px;
                    margin-top: 5px;
                }
            }
            
            .actions{
                display: flex;

                .simple-loading:before {
                    width: 26px;
                    height: 26px;
                    border-width: 3px;
                    border-color: $color-light-grey transparent;
                }
            }
        }

        &.removed{
            animation: removeTerm .7s forwards;
        }
        
        .box-text {
            width: 100%;

            .title {
                display: block;
                color: $featured-text;
                font-size: 16px;
            }

            .subtitle {
                font-size: 12px;
                display: table;
                margin-right: 10px;
                float: left;
                
                &.publicado{
                    color: $brand-color;
                }

                &.rascunho{
                    color: $danger;
                }
            }

            .cursor-pointer {
                cursor: pointer;
            }

            .position-right {
                position: absolute;
                right: 0;
            }

            .version {
                font-size: 12px;
                float: left;
            }
        }

        .actions{
            display: none;
            .btn+.btn{
                margin-left: 10px;
            }
        }
    }

}

@media (min-width:map-get($breakpoints, small)) {
    .list-container{
        .list-header{
            .filter-box{
                .filter-option{
                    .date-filter-container {
                        padding-left: 40px;

                        .date-filter{
                            width: auto;
                            
                            &+.date-filter {
                                margin-left: 40px;
                            }

                            label {
                                font-size: 14px;
                                margin-right: 5px;
                            }
                            
                            .date {
                                width: 75px;
                                height: auto;
                                font-size: 14px;
                            }
                        }

                        .icon{
                            color: $label-icon-grey;
                            display: flex
                        }
                    }
                }
                
                .filter-options-container {
                    .filter-select-option {
                        padding: 10px 15px 10px 10px;
                    }
                }
            }
        }

        .list-box {
            .actions{
                display: flex;
                padding-left: 20px;
                white-space: nowrap;
            }
        }
    }
}

@media (min-width:map-get($breakpoints, large)) {
    .list-container{
        .list-header{
            flex-direction: row;
            align-items: center;
            padding: 25px;

            .filter-box{
                order: initial;
                margin: 0;
            }
            
            .order-box {
                display: flex;
                order: initial;

                .order-label{
                    margin:0 5px 0 20px;
                }
            }

            .force-update {
                margin-left: 20px;
                flex: 0 0 auto;
            }
        }
    }
}

@media (min-width:map-get($breakpoints, extralarge)) {
    
}
.forget-password {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    max-width: 320px;
    display: grid;
    gap: 64px;
    justify-items: center;

    img {
      width: 64px;
    }

    h2 {
      margin: 0;
      color: #213973;
      font-weight: bold;
      text-align: center;
      font-size: 24px;
    }

    h3 {
      margin: 0;
      color: #213973;
      font-size: 20px;
      text-align: center;
    }
  }

  .forget-password-inputs {
    width: 100%;
    max-width: 400px;
    display: grid;
    gap: 16px;

    input {
      width: 100%;
      border: none;
      background-color: transparent;
      padding: 16px;
      border-bottom: 2px solid #92A4B9;

      &::placeholder {
        color: #92A4B9;
      }
    }

    .error {
      color: #cc3333;
    }

    .success {
      color: #00cc00;
    }
  }
}

.login-button {
  position: relative;
  padding: 12px 24px 16px;
  color: #fff;
  background-color: #fff;
  border: 1px solid #213973;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  z-index: 1;

  svg {
    width: 14px;
    height: 14px;
    fill: #213973;
  }

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    background-color: #213973;
    border-radius: 4px;
    z-index: -1;
  }

  &:hover, &:disabled {
    color: #213973;

    &::before {
      background-color: #BDDEF6;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

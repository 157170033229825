.ant-tour {
  min-width: fit-content;
}
@media (max-width: 1100px){
  .ant-tour {
    width: 90%;
    min-width: auto;
  }
  
  iframe{
    width: 100% !important;
    min-width: auto;
  }
}
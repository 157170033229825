.add-publico-alvo .btn {
	width: 100%;
}

.error-duplicated {
	display: block;
	color: #721c24;
	background-color: #f5c6cb;
	padding: 10px 15px;
	border-radius: 3px;
	margin-top: 15px;
}

.container-result {
	padding-top: 30px;
	display: table;
	width: 100%;

	.no-results {
		display: table;
		margin: 20px auto 50px;
		color: $text-grey;
	}

	.container-publico-alvo {
		padding-bottom: 15px;

		.title {
			color: $featured-text;
		}

		.result {
			margin-top: 15px;

			.container-result {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;
				background-color: $color-light-grey;
				padding: 15px;

				.label {
					display: block;
					font-size: 10px;
					color: $text-grey;
				}

				.value {
					display: block;
					margin-bottom: 15px;
					color: $featured-text;
				}
			}
		}
	}
}

.content-main-wrapper {
	width: calc(100% - 280px);
}

.content-main {
	.action-container {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		.remove-action .actions {
			display: flex;

			.btn:first-child {
				order: 1;
				margin-left: 10px;
			}
		}

		.default-actions {
			padding-top: 20px;
		}

		.btn + .btn {
			margin-left: 10px;
		}
	}
}

@media (min-width: map-get($breakpoints, small)) {
	.content-main {
		.action-container.edit-page {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.remove-action .actions {
				.btn:first-child {
					order: 0;
					margin-left: 0;
				}
			}

			.default-actions {
				padding-top: 0;
			}
		}
	}
}

@media (min-width: map-get($breakpoints, large)) {
	.add-publico-alvo .btn {
		width: 100%;
		margin-top: 27px;
		padding-left: 0;
		padding-right: 0;
		font-size: 12px;
	}
}

@keyframes removeTerm {
	from {
		transform: translateX(0);
		opacity: 1;
	}
	to {
		transform: translateX(-50px);
		opacity: 0;
	}
}

.content-box{
    background: $white;
    box-shadow: 2px 2px 5px 0 rgba(73, 93, 189, .1);
    padding: 15px;
    margin-bottom: 30px;

    &.bi-content{
        height: calc(100vh - 92px);
        padding: 0;
        -webkit-overflow-scrolling: touch;

        > div {
          height: 100%;
          padding: 10px;
          box-sizing: content-box;
        }
    }
}

@media (min-width:map-get($breakpoints, large)) {
    .content-box{
        padding: 25px;
    }
}

// -----------------------------------------------------------------------------
// Basic typography style text
// -----------------------------------------------------------------------------

body {
    color: $text-color;
    font: normal 14px $text-font;
    line-height: 1.6;
    letter-spacing: .5px
}

a {
    color: $brand-color;
    text-decoration: none;

    @include on-event {
        color: $text-color;
        text-decoration: underline;
    }
}

p {
    margin: 0 0 15px;

    &:last-child {
        margin: 0;
    }
}

h1 {
    font-size: 20px;
    margin: 20px 0 30px;
    font-weight: 400;
    color: $featured-text;
    padding-bottom: 20px;
    border-bottom: 1px solid $separator;
}

h2 {
    font-size: 16px;
    margin: 30px 0 10px;
    color: $featured-text;
    font-weight: 400;
}

h3 {
    font-size: 14px;
    margin: 10px 0 20px;
    color: $text-color;
    font-weight: 400;
}

@media (min-width:map-get($breakpoints, small)) {
    h1 {
        font-size: 28px;
        margin-top: 30px;
    }
}

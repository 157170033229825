// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body{
  background-color: #fff;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

iframe{
  border: 0
}
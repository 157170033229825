.tenant-warning {
	width: 100%;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 16px;
	border-radius: 8px;
	background: linear-gradient(135deg, #F21905 0%, #F23838 100%);

	span {
		color: #fff;
	}
}

.principal-indicators {
	margin-bottom: 30px;

	.img-indicator {
		height: 110px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 8px;
		}
	}

	.indicator {
		width: 100%;
		margin-top: 15px;
		padding: 16px 32px 16px 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: 1px solid #DFE3E5;
		border-radius: 8px;
		color: $newDarkBlue;
		position: relative;
		cursor: pointer;

		&:hover {
			text-decoration: none;
		}

		img {
			width: 64px;
		}

		div {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		.value {
			font-size: 38px;
			display: block;
			font-weight: 500;
			line-height: 110%;

			.simple-loading:before {
				width: 14px;
				height: 14px;
				border-width: 3px;
				margin-bottom: 10px;
				border-color: #fff6 transparent #fff6 transparent;
			}
		}

		.label {
			display: block;
			font-size: 12px;
			line-height: 110%;
		}
	}
}

.dashboard {
	h2 {
		margin: 0 0 20px;
		font-size: 28px;
		font-weight: 600;
		color: #4052b5;
		line-height: 100%;
	}

	.nuvem {
		overflow: hidden;
		position: relative;
		padding-bottom: 240px;

		img {
			width: 100%;
			position: absolute;
			left: 0;
			right: 0;
			top: calc(100% - 250px);
		}
	}

	.chamados {
		.lista-chamados-loading {
			display: flex;
			width: 100%;
			height: 100%;
			justify-content: center;
			align-items: center;
			padding: 50px;

			.simple-loading:before {
				border-color: #e6e6e6 transparent #e6e6e6 transparent;
			}
		}

		.no-result {
			display: block;
			padding: 16px 0;

			.id {
				display: block;
				height: 10px;
				width: 100px;
				background: linear-gradient(135deg, #efefef 0%, #fdfdfd 100%);
			}

			.title {
				display: block;
				margin-top: 5px;
				width: 260px;
				height: 10px;
				background: linear-gradient(135deg, #f5f5f5 0%, #fbfbfb 100%);
			}
		}

		.lista-vazia {
			position: relative;
			padding: 20px;
			max-width: 390px;
			margin: 0 auto;

			img {
				display: block;
				width: 100%;
			}

			.title {
				position: absolute;
				display: block;
				left: 20px;
				right: 20px;
				text-align: center;
				font-weight: 600;
				bottom: 27%;
				line-height: 1.1;
			}
		}

		.chamado {
			display: flex;
			align-items: center;
			padding: 10px 0;
			cursor: pointer;

			& + .chamado {
				border-top: 1px solid #f2f2f2;
			}

			.chamado-content {
				width: 100%;
				padding-right: 10px;
			}

			.header {
				.id-type {
					font-size: 12px;
					color: #9e9e9e;
				}

				.status {
					display: inline-block;
					margin-left: 8px;
					border-left: 3px solid;
					padding-left: 5px;
					line-height: 12px;
					font-size: 10px;
					font-weight: 600;

					&.aguardando-atendimento {
						color: $brand-color;
					}
					&.em-atendimento {
						color: $brand-color;
					}
					&.aguardando-cliente {
						color: #e5a122;
					}
					&.cancelado {
						color: $backround-erro-clear;
					}
					&.mesclado {
						color: $backround-erro-clear;
					}
					&.suspenso {
						color: #ef8e8e;
					}
					&.agendado {
						color: #ef8e8e;
					}
					&.resolvido {
						color: #5cb975;
					}
					&.informacoes-fornecidas {
						color: #5cb975;
					}
					&.aceito-pelo-cliente {
						color: #5cb975;
					}
				}
			}

			.title {
				color: #212b36;
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.icon {
				font-size: 8px;
				color: #dfe3e8;
			}
		}

		.actions-conteiner {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			text-align: right;
			margin-top: 20px;

			.ver-todos {
				display: inline-table;
				margin-right: 10px;
				line-height: 1.2;
			}

			.btn {
				flex: 0 0 auto;
			}
		}
	}

	.techdrops {
		// background-image: url("../assets/images/bg-techdrops.svg");
		background-repeat: no-repeat;
		background-position: center right -270px;
		background-size: auto 100%;

		.presentation {
			display: block;
			max-width: 390px;
		}

		.img-container {
			margin-top: 30px;
			margin-bottom: 15px;

			a {
				position: relative;
				display: block;
				margin-bottom: 15px;
			}

			img {
				width: 100%;
				display: block;
				box-shadow: 0px 3px 8px rgba(0,0,0,.8)
			}

			.ver-mais {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #000000c9;
				color: #fff;
				font-weight: 600;
			}
		}
	}
}

.links-home {
	margin-bottom: 30px;
	padding: 8px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: $newBlue;
	border-radius: 8px;
	transition: .2s background-color;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}

	h2 {
		margin: 0;
		color: #fff;
		z-index: 1;
	}

	img {
		width: 48px;
	}
}


@media (min-width: map-get($breakpoints, small)) {
	.principal-indicators {
		.indicator {
			height: 100%;
			margin: 0;
			overflow: hidden;

			.value {
				font-size: 42px;

				.simple-loading:before {
					width: 12px;
					height: 12px;
				}

				font-weight: 600;
			}
		}
	}

	.dashboard {
		.chamados {
			.lista-vazia {
				.title {
					font-size: 18px;
					bottom: 27%;
				}
			}
		}

		.techdrops {
			background-position: center right -270px;

			.presentation {
				max-width: 370px;
			}
		}
	}
}

@media (min-width: map-get($breakpoints, large)) {
	.principal-indicators {
		.indicator {
			.value {
				font-size: 56px;
			}
		}
	}

	.dashboard {
		.container-dashboard-principal {
			margin-bottom: 30px;
		}

		.nuvem {
			height: 100%;
			margin-bottom: 0;
		}

		.chamados {
			height: 100%;
			display: flex;
			flex-direction: column;
			margin-bottom: 0;

			.lista-vazia {
				.title {
					bottom: 21%;
				}
			}

			.lista-chamados {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.actions-conteiner {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				text-align: right;
				margin-top: 15px;

				.ver-todos {
					margin-right: 30px;
				}
			}
		}

		.techdrops {
			background-position: center right -70px;

			.presentation {
				max-width: 470px;
			}

			.img-container {
				margin-bottom: 30px;

				a {
					margin-bottom: 0;
				}
			}
		}
	}
}

@media (min-width: map-get($breakpoints, extralarge)) {
	.dashboard {
		.techdrops {
			background-position: center right;

			.presentation {
				max-width: 520px;
			}
		}

		.chamados {
			.lista-vazia {
				.title {
					bottom: 28%;
				}
			}
		}
	}
}

@import '../components/ebook';
@import '../components/services';
@import '../components/blog';

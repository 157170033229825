// -----------------------------------------------------------------------------
// This file contains styles that are specific to the error pages.
// -----------------------------------------------------------------------------

.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(120deg, $backround-erro-clear, $btn-error, $backround-erro);
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;

    &.error-401{
        background: linear-gradient(120deg, $color-orange-medio-401, $color-orange-clear-401, $color-orange-401);

        .error-container {
            .error-text-container {
                .btn-error{
                    &:hover{
                        color: $color-orange-clear-401;
                    }
                }
            }
        }
    }

    &.error-403{
        background: linear-gradient(120deg, $color-orange-medio-403, $color-orange-clear-403, $color-orange-403);

        .error-container {
            .error-text-container {
                .btn-error{
                    &:hover{
                        color: $color-orange-clear-403;
                    }
                }
            }
        }
    }

    &.error-404{
        background: linear-gradient(120deg, $color-blue-medio-404, $color-blue-clear-404, $color-blue-404);

        .error-container {
            .error-text-container {
                .btn-error{
                    &:hover{
                        color: $color-blue-clear-404;
                    }
                }
            }
        }
    }

    .error-container {
        display: flex;
        align-items: center;
        justify-content: center;
        color:  $white;
        flex-direction: column;
        text-align:center;
        padding:30px;

        .logo {
            width: 80px;
            margin-bottom:20px
        }

        .error-text-container {
            h1 {
                margin: 0 0 10px;
                font-size: 120px;
                font-weight: 600;
                color: $text-erro-h1-color;
                line-height: 160px;
                display:block
            }

            .error-title{
                font-size:20px;
                font-weight:500;
                margin-bottom: 10px;
                display: block;
            }

            .error-text{
                font-size:14px;
                font-weight:100;
                margin-bottom:40px;
                display:block;
                max-width: 500px;
            }

            .btn-error{
                border:1px solid $white;
                background: 0 0;
                cursor: pointer;
                border-radius:5px;
                padding:9px 15px;
                color:$white;
                user-select:none;
                outline: none;
                display: table;
                margin: 0 auto;

                @include on-event {
                    color: $white;
                    text-decoration: none;
                }

                &:hover{
                    background: $white;
                    color: $btn-error;
                }

                i{
                    font-size: 10px;
                    margin-right: 10px
                }
            }
        }
    }
}

@media (min-width:map-get($breakpoints, medium)) {
    .error-page{
        .error-container {
            flex-direction: row;
            text-align:left;

            .logo {
                width: 100px;
                margin: 0 70px 0 0;
            }

            .error-text-container {
                border-left: 1px solid $text-erro-color;
                padding-left: 70px;

                h1 {
                    font-size: 160px;
                    line-height: 190px;
                }

                .error-title{
                    font-size:34px;
                    line-height: 40px;
                    margin-bottom: 20px;
                }

                .error-text{
                    font-size:22px;
                }

                .btn-error{
                    margin: 0;
                }
            }
        }
    }
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------

.main .authenticated{
	padding-top: 60px;
}

.main-header{
	display: flex;
	align-items: center;
	height: 92px;
	background-color: $brand-color;
	padding: 32px;
	color: $white;
	user-select: none;
	position: fixed;
	top:0;
	left: 0;
	right: 0;
	z-index: 999;

	.header-content{
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 3fr .5fr;
		gap: 16px;
		align-items: center;
	}

	.logo{
		display:table;
		justify-self: flex-end;

		img{
			height: 30px;
			display:table;        
		}
	}
}

@media (min-width:map-get($breakpoints, medium)) {
    .main .authenticated{
			padding-top: 0;
		}
		
		.authenticated .content-container {
			display: grid;
			grid-template-columns: 280px calc(100% - 280px);

			.content-holder {
				width: 95%;
				max-width: 850px;
				padding: 64px 0;
				justify-self: center;
			}
			.content-holder-full-width{
				width: 100%;
				justify-self: center;
				max-width: 100%;
			}
		}

    .main-header{
			position: static;

			.logo {
				margin-left: 128px;

				img{
					height: 40px;      
				}
			}

			.action-container{
				display: flex;
				justify-content: flex-end;
				align-items: center;
				flex: 0 0 66.666667%;
				max-width: 66.666667%;

				.rigth-conteiner{
					display: flex;
					align-items: center;
				}
			}
    }
}

@media (min-width:map-get($breakpoints, large)) {
	.main-header {
		padding: 0;

		.action-container{
			width: calc(100% - 280px);
			max-width: 100%;
			padding-right: 32px;
			flex: 0 0 75%;
		}

		.header-content{
			grid-template-columns: 280px 100%;
			gap: 0;
		}
	}
}

@import './headerSignature';

.user-container{
    display: none;
    position: relative;
    margin-left: 25px;

    &:hover .user-nav-container{
       display: block; 
    }

    .user-nav-container{
			position: absolute;
			top: 100%;
			right: 0;
			min-width: 200px;
			padding: 32px 20px 16px;
			display: none;
			background-color: $white;
			border-radius: 26px;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, .29);
			font-size: 16px;
			z-index: 1001;

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				width: 16px;
				height: 16px;
				transform: translate(-50%, -8px) rotate(45deg);
				background-color: #fff;
			}
			
			h5 {
				margin: 0 0 16px;
				color: $newBlueGrey;
				font-size: 9px;
				font-weight: normal;
				letter-spacing: .05rem;
				text-transform: uppercase;
			}

			.user-nav {
				color: $newDarkBlue;

				.nav-item {
					padding: 8px 0;
					display: flex;
					align-items: center;
					color: inherit;
					text-decoration: none;
					cursor: pointer;

					&:hover{
						background: $light-grey;
					}

					img {
						width: 20px;
						margin-right: 8px;
					}

					.nav-link{
						text-decoration: none;
						color: $nav-color;
					}
				}
			}
    }
}

.user-info-container {
	padding: 16px 0;
	display: flex;
	align-items: center;
	cursor: pointer;

	.user-first-name {
		display: block;
		margin-left: 10px;
	}
	
	.user-signature-name{
		margin-left: 10px;
	}

	.dropdown {
		width: 14px;
		margin-left: 16px;
	}
}

.user-photo{
    width: 45px;
    height: 45px;
    background-color: $white;
    border-radius: 50%;
    overflow: hidden;
    display: table;
    flex: 0 0 auto;
}

.user-nav-mobile{
	background: $brand-color;
	padding: 15px;
	display: flex;
	align-items: center;
	position: absolute;
    top: 0;
    width: 100%;
    left: 0;
	min-width: fit-content;

	@media (min-width: 768px) {
		display: none;
	}

	.user-nav-container{
		padding-left: 15px;
		line-height: 1;
		color: white;

		.user-name {
			font-size: 16px;
			display: block;
			font-weight: 300;
		}
						
		.user-logout {
			display: table;
			margin-top: 3px;
			font-size: 12px;
			color: $icon-color-clear;
			cursor: pointer;
	
			.icon {
				margin-right: 5px;
			}
		}
	}
}

@media (min-width:map-get($breakpoints, medium)) {
	.user-container{
		display: block;
	}
}
.container-icon{
    position: relative;

    .icon{
        font-size: 22px;
        display: table;
    }

    .notification-number{
        display: block;
        background: $danger;
        font-size: 10px;
        border-radius: 50%;
        position: absolute;
        top:-4px;
        right:-7px;
        padding: 0 5px;
    }
}

.login-page {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-end;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    z-index: -1;
  }

  .login-wrapper {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px 0 0 32px;
    background-color: #fff;

    > div {
      display: grid;
      gap: 32px;
    }

    img {
      width: 64px;
      justify-self: center;
    }

    h2 {
      margin: 0;
      color: #213973;
      font-weight: bold;
      text-align: center;
      font-size: 24px;
    }

    h3 {
      margin: 0;
      color: #213973;
      font-size: 20px;
      text-align: center;
    }

    .login-buttons {
      width: 70%;
      display: grid;
      gap: 16px;
      justify-self: center;

      .forget-password-btn {
        position: relative;
        padding: 12px 8px;
        color: #213973;
        background-color: #fff;
        border: 1px solid #213973;
        border-radius: 4px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        z-index: 1;

        &:hover {
          color: #fff;
          background-color: #213973;
        }
      }
    }
  }
}

.login-button {
  position: relative;
  padding: 12px 8px 16px;
  color: #fff;
  background-color: #fff;
  border: 1px solid #213973;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    background-color: #213973;
    border-radius: 4px;
    z-index: -1;
  }

  &:hover {
    color: #213973;

    &::before {
      background-color: #BDDEF6;
    }
  }
}

@media screen and (max-width: 768px) {
  .login-page {
    .login-wrapper {
      width: 100%;
      border-radius: 0;
    }
  }
}

.blog-wrapper {
  width: 100%;
  padding: 64px 32px 32px;
  border: 1px solid #DFE3E5;
  border-radius: 8px;

  h3 {
    margin-bottom: 48px;
    color: $newDarkBlue;
    font-size: 24px;
    font-weight: lighter;
    text-align: center;
  }
}

.blog-posts {
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  .blog-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $newDarkBlue;

    &:hover {
      text-decoration: none;

      .blog-img img {
        width: 110%;
        height: 110%;
      }
    }

    .blog-img {
      position: relative;
      width: 100%;
      height: 140px;
      margin-bottom: 16px;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        transition: .3s width, .3s height;
        object-fit: cover;
      }
    }

    h3 {
      margin: 0;
      margin-bottom: 6px;
      color: $newDarkBlue;
      font-size: 17px;
      line-height: 23px;
      font-weight: lighter;
      text-align: left;
    }

    h4 {
      margin: 0;
      font-weight: normal;
    }
  }
}

@media (min-width: map-get($breakpoints, medium)) {
  .blog-posts {
    grid-template-columns: repeat(3, 1fr);
  }
}

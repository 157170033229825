.breadcrumb{
    margin-bottom: 20px;
    user-select: none;

    a{
        color: $text-color;

        .icon{
            font-size: 12px;
            margin-right: 5px;
        }

        @include on-event {
            color: $brand-color;
            text-decoration: none;
        }
    }
}

@media (min-width:map-get($breakpoints, medium)) {
    .breadcrumb{
        margin-bottom: 30px;
    }
}
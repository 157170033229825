.input-group-container {
    position: relative;
    
    .simple-loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $simple-loading;
        display: flex;
        align-items: center;
        justify-content: center;

        &:before {
            width: 16px;
            height: 16px;
            border-width: 3px;
        }
    }
}
.content-box-nav {
	background: $newGrey;
	padding: 0;

	&.bi-content {
    height: 722px;
		padding: 0;
		overflow: auto hidden;
		-webkit-overflow-scrolling: touch;

		> div {
      height: 722px;
      padding: 10px;
			box-sizing: content-box;
		}
	}
}

@media (min-width:map-get($breakpoints, large)) {
	.content-box-nav {
		width: 280px;
		min-height: 95vh;
		padding: 0;
	}
}

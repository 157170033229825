.simple-header {
    background-color: $brand-color;
    padding: 15px 0;
    color: $white;

    .header-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logo {
        display: table;

        img {
            height: 40px;
            display: table;
        }
    }

    .user-container{
        display: block;
    }
}

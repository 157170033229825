.picky {
    margin-top: 5px !important;
}
.picky__dropdown{
	overflow-y: auto !important;
}
.picky__input {
    width: 100%;
    background-color: white;
    border-width: 1px;
    border: 1px solid #DFE3E8;
    border-style: solid;
    border-radius: 3px;
    padding: 5px;
    text-align: left;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 38px;
}
.picky__filter__input {
    width: 100%;
    padding: 5px;
    display: inline-block;
    border: 1px solid #DFE3E8;
    border-radius: 3px;
    position: relative;
    height: 35px;
}
.picky__input--disabled {
    background: #F1F1F1;
    border-color: #C4CDD5;
}
.picky__placeholder {
    color: black;
}
.picky__dropdown .option:hover, .picky__dropdown li:hover {
    background-color: #1D8BEF;
    color: #fff;
}
.picky__dropdown .option.selected, .picky__dropdown li.selected {
    background-color: #1D8BEF;
    color: #fff;
}

.contact-status {
	display: inline-block;
	font-size: 10px;
	background: #495dbd;
	border-radius: 4px;
	margin-left: 5px;
	padding: 2px 3px;
	color: #fff;

	&.syncing {
		background: #495dbd;
	}

	&.active {
		background: #33b83a;
	}

	&.inactive {
		background: #727272;
	}
}

.list-container .user-list {
	.sync .box-text {
		.title {
			&:after {
				content: "sync";
				display: inline-block;
				font-size: 10px;
				background: #495dbd;
				border-radius: 4px;
				margin: 3px 5px 0 0;
				padding: 0px 3px 2px;
				float: left;
				color: #fff;
			}
		}
	}

	.box-text {
		width: 100%;

		.subtitle {
			float: none;
			word-break: break-all;
			margin-top: 5px;
		}

		.see-profiles {
			margin-top: 5px;
			color: $brand-color;
			font-size: 12px;
			cursor: pointer;
			display: none;

			.icon {
				display: inline-block;
				margin-left: 5px;
				font-size: 8px;
			}
		}

		.loading-profiles {
			display: flex;
			justify-content: center;
			padding: 10px;
			background: $color-light-grey;
			margin-top: 10px;

			.simple-loading:before {
				width: 20px;
				height: 20px;
				border-width: 2px;
				border-color: #cccccc transparent;
			}
		}

		.empty-profiles {
			display: flex;
			justify-content: center;
			padding: 10px;
			background: $color-light-grey;
			margin-top: 10px;
		}

		.perfil-list {
			display: none;

			.perfil-item {
				background: $color-light-grey;
				padding: 10px;
				margin-top: 10px;

				.label {
					font-size: 10px;
					display: block;
					color: $text-grey;
				}

				.value + .label {
					margin-top: 10px;
				}

				.value {
					color: $featured-text;
					font-size: 12px;
				}
			}
		}
	}
}

.btn-left {
	.btn + .btn {
		margin-left: 10px;
		justify-content: flex-start;
		align-items: flex-end;
	}
}

.SucessCodAdvisor {
	border-color: #5cb975 !important;
}

.ErrorCodAdvisor {
	border-color: #ffa7a7 !important;
}

.tooltip-alerts {
	position: relative;
	display: table;

	.tooltip-message {
		position: absolute;
		top:83%;
		background: #495dbd;
		color:#fff;
		padding: 5px 10px;
		border-radius: 3px;
		z-index: 1;
		-webkit-user-select: none;
  		-moz-user-select: none; 
  		-ms-user-select: none; 
		  user-select: none;
		  min-width: 315px;

		  &:before {
			content: "";
			position: absolute;
			left: 4px;
			top: -5px;
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid #5367c4;
		}
	}
}

.check-user-nuvem {
    position: relative;
	display: table;
	
	label{
		margin-top: 24px;
	}
	.tooltip {
		position: absolute;
		left: 0;
		top: 70%;
		background: #495dbd;
		color: #fff;
		padding: 5px 10px;
		border-radius: 3px;
		-webkit-user-select: none;
  		-moz-user-select: none; 
  		-ms-user-select: none; 
  		user-select: none;
		display: none;
		min-width: 315px;
		z-index: 999;
		&:before {
			content: "";
			position: absolute;
			left: 4px;
			top: -5px;
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-bottom: 5px solid #5367c4;
		}
	}

	&:hover .tooltip {
		display: table;
	}
}

@media (max-width: 370px){
	.tooltip-alerts{
		.tooltip-message{
			display: none;
		}
	}
}

@media (min-width: map-get($breakpoints, small)) {
		.list-container .user-list {
		.box-text {
			.see-profiles {
				display: table;
				user-select: none;
			}

			.perfil-list {
				display: block;
			}
		}
	}
}

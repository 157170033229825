.header-signature {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
	padding: 12px 22px 12px 32px;
	cursor: pointer;
	
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		width: 1px;
		height: 60%;
		background-color: #fff;
		transform: translate(0, -50%);
  }
  
  .dropdown {
    position: absolute;
    top: 50%;
    right: 12px;
    width: 14px;
    transform: translate(0, -50%);
  }

	strong, 
	small {
		display: block;
	}

	strong {
		font-size: 18px;
		line-height: 1.2;
	}
}

.header-signature-list {
  position: absolute;
  left: 50%;
  top: 100%;
  width: 240px;
  padding: 32px 16px 22px;
  transform: translate(-50%, 0);
  background-color: #fff;
  border-radius: 26px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .29);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 16px;
    height: 16px;
    transform: translate(-50%, -8px) rotate(45deg);
    background-color: #fff;
  }

  h5 {
    margin: 0;
    color: $newBlueGrey;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: .05rem;
    text-transform: uppercase;
  }

  .is_loading {
    width: 100%;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header-signature-item {
  width: 100%;
  padding: 16px 0;
  background-color: #fff;
  border-bottom: 1px solid #BCC8D1;

  &:hover {
    background-color: #F4F5F7;
  }

  span,
  small {
    color: $newDarkBlue;
  }

  span {
    font-size: 16px;
  }

  small {
    font-size: 12px;
    letter-spacing: .28px;
  }
}

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
    @if $self {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
    @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}

@mixin appearance ($value: none) {
    -webkit-appearance: $value;
    -moz-appearance: $value;
    -ms-appearance: $value;
    -o-appearance: $value;
    appearance: $value;
}

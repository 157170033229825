.simple-loading{
    &:before{
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 6px solid $brand-color;
        border-color: $brand-color transparent $brand-color transparent;
        animation: simple-loading 1.2s linear infinite;
    }
}

@keyframes simple-loading {
    0%  { transform: rotate(0deg) }
    100%{ transform: rotate(360deg) }
}
.termos-de-aceite{
    background: $white;
    border: 1px solid $separator;
    padding: 15px;
    text-align: center;

    &+.termos-de-aceite{
        margin-top: 15px;
    }

    .title{
        font-size:18px;
        color: $featured-text;
        display: block;
    }
    
    a:hover{
        text-decoration:none;
    }

    .icon{
        font-size: 12px;
        margin-right: 5px
    }

    .btn{
        margin: 20px auto 0;
        flex: 0 0 auto;
    }
}

@media (min-width:map-get($breakpoints, medium)) {
    .termos-de-aceite{
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        
        .btn{
            margin: 0;
        }
    }
}

@media (min-width:map-get($breakpoints, large)) {
    .termos-de-aceite{
        padding: 25px;
    }
}
// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.loading-btn {
	opacity: 0.30;
	color: #E52222;
}

.btn {
    margin: 0;
    padding: 10px 15px;
    background: $white;
    border: 1px solid $separator;
    color: $featured-text;
    min-width: 60px;
    text-align: center;
    user-select: none;
    border-radius: 3px;
    line-height: normal;
    cursor: pointer;
    display: inline-table;
    outline: none;

    .simple-loading:before {
        width: 15px;
        height: 15px;
        border-width: 2px;
        border-color: $white transparent;
        display: table;
        margin: 0 auto;
    }

    &:hover {
        color: $featured-text;
        text-decoration: none;
        background: $separator;
    }

    &.btn-default {
        background-color: $brand-color;
        color: $white;
        border-color: $brand-color;

        &:hover {
            background: $featured-default;
        }
    }

    &.btn-danger {
        background-color: $danger;
        color: $white;
        border-color: $featured-danger;

        &:hover {
            background: $featured-danger;
        }
    }

    &.btn-loading {
        pointer-events: none;
        cursor: no-drop;
    }

    &:disabled,&:disabled:hover{
        color: $white;
        background-color: $btn-grey;
        border-color: $btn-grey;
        cursor: no-drop
    }

}


.nav-mobile{
    .nav-bars{
        width:25px;
        height:19px;
        position:relative;
        z-index:901;
        
        .bar{
            position: absolute;
            opacity: 1;
            left: 0;
            background: $white;
            display: block;
            width: 20px;
            height: 2px;
            transform: rotate(0deg);
            transition: .25s ease-in-out;
            
            &:nth-child(1){
                top:0;
                transform-origin:left center;
            }

            &:nth-child(2){
                width:24px;
                top:7px;
                transform-origin:left center
            }

            &:nth-child(3){
                top:14px;
                transform-origin:left center;
            }
        }
    }

    &.open .bar:nth-child(1){
        transform:rotate(45deg);
        top:-1px;
        left:3px
    }

    &.open .bar:nth-child(2){
        width:0;
        opacity:0
    }

    &.open .bar:nth-child(3){
        transform:rotate(-45deg);
        left:3px
    }

    .box-shadow{
        display: none;
        position:fixed;
        z-index: 1010;
        top: 60px;
        right: 0;
        bottom: 0;
        left: 0;
        background: $nav-mobile;
    }

    &.open .box-shadow{
        display: block;
        transform: translateX(0);
    }
    
    .nav-container {
        opacity: 0;
        transform: translateX(-100%);
        position: fixed;
        z-index: 1011;
        top: 60px;
        border-top: 1px solid $separator;
        border-right: 1px solid $separator;
        background: $white;
        display: block;
        left: 0;
        bottom: 0;
        width: 260px;
        overflow: auto;
        transition: .4s;
    }

    &.open .nav-container{
        opacity: 1;
        transform: translateX(0);
    }
}

.search-box-container{
    border-top: 1px solid $separator;
    border-bottom: 1px solid $separator;
    padding: 15px;

    .search-box{
        display: block;
    }
}

.nav-box{
    padding: 15px;
}

@media (min-width:map-get($breakpoints, medium)) {
    .nav-mobile{
        display: none;

        .nav-container{
            display: none;
        }
    }
}
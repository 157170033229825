.term-container{
    div:nth-child(1){
        width: 95px;
    }

    div:nth-child(2){
        width: 148px;
    }

    div {      
        position:relative;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        i {
            width: 30px;
            margin: 0 auto;
        } 
        
        .simple-loading {
        position: absolute;
        padding: 5px;
        bottom: 5px;
    
            &:before {
                width: 16px;
                height: 16px;
                border-width: 3px;
            }
        }

        .term-loading {
            color:rgb(172, 172, 172) !important;
        }

        .term-loading:hover {
            color: #979797 !important;
            text-decoration: none;
            background: #ffffff !important;
        }

        .btn:disabled {
            background-color: #ffffff !important;
            border-color: #ffffff !important;
            cursor: default;
        }
    }        
} 



.term-text{
    margin-bottom:125px;
}

.term-action {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;
    border-top: 1px solid $separator;
    padding: 15px 0;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);

    .btn{
        display:block;
        width: 100%
    }

    .btn+.btn {
        margin-top: 10px;
    }
}

@media (min-width:map-get($breakpoints, small)) {
    .term-text{
        margin-bottom:80px;
    }

    .term-action {
        padding: 20px 0;

        .actions {
            float: right;
        }

        .btn{
            display:inline-table;
            width: auto;
        }

        .btn+.btn {
            margin: 0 0 0 15px;
        }
    }
}
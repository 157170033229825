.nav-base{
	height: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media (max-width: 768px) {
		padding-top: 80px;
	}

	.nav-group{
		padding-bottom: 32px;
		margin-bottom: 32px;
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid $newDarkGrey;

		&:last-child {
			border-bottom: none;
		}

		.group-title {
			margin-bottom: 16px;
			font-size: 12px;
			color: $newBlue;
			text-transform: uppercase;
			letter-spacing: .15rem;
		}
	}

	.nav-item {
		margin-top: 0;
		padding: 4px 0;
		color: $newDarkBlue;
		font-size: 14px;

		img {
			display:block;
			width: 32px;
			margin-right: 20px;
			color: $brand-color;
		}

		&.active, &:hover{
			color: $brand-color;
			text-decoration: none;
		}
	}

	.nav-child{
		padding-left: 45px;

		.nav-item{
			font-size: 14px;
			margin-top: 10px
		}
	}
}

.nav-base-configs {
	border-top: 1px solid $newDarkGrey;
	padding-top: 32px;

	h5 {
		margin-bottom: 16px;
		font-size: 12px;
		color: $newBlue;
		font-weight: normal;
		letter-spacing: .15rem;
		text-transform: uppercase;
	}

	.nav-item {
		padding: 8px 0;

		img {
			width: 22px;
		}
	}
}

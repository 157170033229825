.file-list{
  padding-top: 20px;

  div {
    justify-content: center;

    .simple-loading {
      padding: 5px;
      top: 50px;
      left: 0;
      right: 20px;
      bottom: 0;

      &:before {
          width: 20px;
          height: 20px;
          border-width: 3px;
      }
  }
  }

 li {
   width: 100%;
   list-style: none;

   .file-loading {
    opacity:0.2;
    }

 div {
    padding-bottom: 3px;
    padding-top: 5px;
    justify-content: space-between;

     
     strong {
        padding-right: 20px;
      }

       span {
         text-align: center;
         padding-left: 20px;
         border-left: 1px solid #bbbaba;
       }
   }

   .file-status {
     display: flex;
    

    i {
      width: 30px;
    } 

    .simple-loading {
      padding: 5px;
      top: 50px;
      left: 0;
      right: 20px;
      bottom: 0;

      &:before {
          width: 16px;
          height: 16px;
          border-width: 3px;
      }
  }

    a {
      #eyes-icon{
        margin-left:10px;
      }

      #eyes-icon:hover {
        color:#4052b5 !important;
      }
    }
    
      
     #close-icon{
        cursor: pointer;
     }

     #close-icon:hover {
      color:#e65100;
    }
   }

  }


}
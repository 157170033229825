.input-group.rich-text {
    font-size: inherit;
    font-family: inherit;
    padding: 0;

    .EditorToolbar__root___3_Aqz {
        font-size: inherit;
        font-family: inherit;
        margin: 0;
        padding: 10px 10px 5px;
        background: $color-light-grey;
    }
        
    button, .Dropdown__value___34Py9 {
        background: $white;
        border-color: $button-dropdown;
    }
    
    .IconButton__isActive___2Ey8p {
        background: $icon-button;
    }
}

.mkt-lead {
	overflow: hidden;
	position: relative;
	padding-bottom: 240px;

	.mkt-content {
		padding: 15px;

		h2 {
			margin: 0 0 20px;
			font-size: 28px;
			font-weight: 600;
			color: #4052b5;
			line-height: 100%;
		}
	}

	img {
		position: absolute;
		left: 0;
		right: 0;
		top: calc(100% - 250px);
		width: 100%;
	}
}

@media (min-width: map-get($breakpoints, large)) {
	.mkt-lead {
		padding: 0;

		.mkt-content {
			padding: 40px 0 40px 40px;
			max-width: 370px;
		}

		img {
			margin: auto;
			top: -10px;
			right: 0;
			bottom: auto;
			left: auto;
			width: 300px;
		}

		&.microsoft365 img {
			top: 0px;
			bottom: 0;
		}

		&.firewall img {
			top: 0;
			bottom: 0;
		}

		&.servidores img {
			top: 0;
			bottom: 0;
		}

		&.desktops img {
			top: 0;
			bottom: -30px;
		}

		&.outsourcing img {
			top: 0;
			bottom: 0;
		}
	}
}

@media (min-width: map-get($breakpoints, extralarge)) {
	.mkt-lead {
		.mkt-content {
			max-width: 400px;
		}

		img {
			top: -40px;
			width: 400px;
		}
	}
}

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Font family
$text-font: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif !default;

/// Color
$white:                 #fff;
$text-erro-color:       #ffffff26   !default;
$text-erro-h1-color:    #fff9       !default;
$light-grey:            #F4F5F7     !default;
$icon-color-clear:      #ffffff99   !default;
$simple-loading:        #ffffffcf   !default;
$color-light-grey:      #F1F1F1     !default;
$button-dropdown:       #d8d8d8     !default;
$icon-button:           #ccc        !default;
$separator:             #DFE3E8     !default;
$backround-erro-clear:  #C7C2C2     !default;
$fieldset:              #c0c0c0     !default;
$btn-error:             #B5B4B4     !default;
$border-color:          #C4CDD5     !default;
$label-icon-grey:       #9E9E9E     !default;
$text-grey:             #A1A2A3     !default;
$backround-erro:        #838383     !default;
$btn-grey:              #6c757d     !default;
$text-color:            #637381     !default;
$featured-text:         #212B35     !default;
$nav-color:             #09090a     !default;
$mark-black:            #000        !default;
$nav-mobile:            #00000099   !default;
$loading-blue-light:    #d1d8ff     !default;
$loading-clear:         #8997e2     !default;
$loading-blue-clear:    #8190e4     !default;
$loading-blue:          #5f74e6     !default;

$color-blue-medio-404:  #43AFFF     !default;
$color-blue-clear-404:  #26C1FA     !default;
$color-blue-404:        #2775F2     !default;

$brand-color:           #4052B5     !default;
$featured-default:      #224EE5     !default;
$not-success:           #69ca69     !default;
$valid_background:      #28A745     !default;
$mark-yellow:           #ff0        !default;
$light-pink:            #ffa7a7     !default;

$color-orange-medio-401:#FF9243     !default;
$color-orange-clear-401:#FA9926     !default;
$color-orange-401:      #F26D27     !default;

$color-orange-medio-403:#FF8B43     !default;
$color-orange-clear-403:#FA8026     !default;
$color-orange-403:      #F25E27     !default;

$danger:                #E94A44     !default;
$not-error:             #ef5149     !default;
$icon-color-danger:     #E65100     !default;
$featured-danger:       #E52222     !default;

/// new colors (revitalização)
$newGrey: #E2E7EA;
$newDarkGrey: #BCC8D1;
$newBlueGrey: #6D8096;
$newDarkBlue: #343C42;
$newBlue: #193A70;
$ouro: #CF2715;
$prata: #E34B27;
$azure: #0058CE;
$onPremise: #DB8D25;
$compliance: #72CA32;
$dispositivos: #6C2CB8;
$suportePremium: #43577A;
$outsourcing: #35BB7A;

/// Breakpoints map
$breakpoints: (
	'small': 576px,
	'medium': 768px,
	'large': 992px,
	'extralarge': 1200px
) !default;
.loading{padding:0;position:fixed;z-index:1099;top:0;right:0;bottom:0;left:0;background:$brand-color;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center}
.spinner{-webkit-animation:rotator 1.4s linear infinite;animation:rotator 1.4s linear infinite}
.path{stroke-dasharray:187;stroke-dashoffset:0;-webkit-transform-origin:center;transform-origin:center;-webkit-animation:dash 1.4s ease-in-out infinite,colors 5.6s ease-in-out infinite;animation:dash 1.4s ease-in-out infinite,colors 5.6s ease-in-out infinite}

/* Animações */
.animate-spin{-moz-animation:spin 2s infinite linear;-o-animation:spin 2s infinite linear;-webkit-animation:spin 2s infinite linear;animation:spin 2s infinite linear;display:inline-block}

.block-loading {
    display: flex;
    padding: 50px;
    align-items: center;
    justify-content: center;
	
	.simple-loading:before {
		border-color: $color-light-grey transparent;
	}
}

@keyframes spin {
	0%{-moz-transform:rotate(0deg);-o-transform:rotate(0deg);-webkit-transform:rotate(0deg);transform:rotate(0deg)}
	100%{-moz-transform:rotate(359deg);-o-transform:rotate(359deg);-webkit-transform:rotate(359deg);transform:rotate(359deg)}
}
@keyframes anim-open {
	0%{opacity:0;-webkit-transform:translate3d(0,50px,0);-moz-transform:translate3d(0,50px,0);-o-transform:translate3d(0,50px,0);-ms-transform:translate3d(0,50px,0);transform:translate3d(0,50px,0)}
	100%{opacity:1;-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
}
@keyframes anim-close {
	0%{opacity:1;-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
	100%{opacity:0;-webkit-transform:translate3d(0,50px,0);-moz-transform:translate3d(0,50px,0);-o-transform:translate3d(0,50px,0);-ms-transform:translate3d(0,50px,0);transform:translate3d(0,50px,0)}
}
@keyframes rotator {
	0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}
	100%{-webkit-transform:rotate(270deg);transform:rotate(270deg)}
}
@keyframes colors {
	0%{stroke: $brand-color }
	25%{stroke: $loading-blue }
	50%{stroke: $loading-blue-clear }
	75%{stroke: $loading-clear }
	100%{stroke: $loading-blue-light }
}


@keyframes dash {
	0%{stroke-dashoffset:187}
	50%{stroke-dashoffset:46.75;-webkit-transform:rotate(135deg);transform:rotate(135deg)}
	100%{stroke-dashoffset:187;-webkit-transform:rotate(450deg);transform:rotate(450deg)}
}
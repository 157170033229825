.services {
  position: relative;
  height: 100%;
  padding: 16px 10px;
  border: 1px solid #DFE3E5;
  border-radius: 8px;
}

.carousel {
  .slider-wrapper.axis-horizontal 
  .slider 
  .slide {
    background-color: transparent;

    img {
      width: inherit;
    }

    .services-item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .services-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
        
        img {
          width: 46px;
          height: auto;
          margin-right: 10px;
        }
    
        h2 {
          margin: 0;
          color: $newDarkBlue;
          font-size: 21px;
          font-weight: normal;
          text-align: left;
        }
      }

      p {
        padding: 0 16px;
        font-size: 14px;
        line-height: 1.3;
        text-align: left;

        &.chamada {
          font-size: 18px;
          font-weight: lighter;
          line-height: 1.3;
        }
      }

      a {
        margin-bottom: 24px;
        padding: 6px 32px;
        display: inline-block;
        background-color: #fff;
        border: 1px solid #DFE3E5;
        border-radius: 8px;
        color: $newBlue;
        font-weight: bold;
        text-transform: uppercase;

        &:hover {
          background-color: $newBlue;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }

  .control-dots {
    margin: 0;

    .dot {
      border: 1px solid $newBlue;
      box-shadow: none;

      &.selected {
        background-color: $newBlue;
        box-shadow: none;
      }
    }
  }
}

.carousel-root {
  height: 100%;

  .carousel, 
  .slider-wrapper,
  .slider,
  .slide {
    height: 100%;
  }
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

.footer {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  background-color: $newBlueGrey;
}

.footer-logo {
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 42px;
  }
}

.footer-midias {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;

    img {
      width: 26px;
    }
  }

  span {
    color: #fff;
    text-align: center;
  }
}

@media (min-width:map-get($breakpoints, medium)) {
  .footer {
    width: 100%;
    height: 90px;
    padding: 0;
    display: grid;
    grid-template-columns: 280px 1fr;
  }

  .footer-logo {
    margin-top: 0;
    border-right: 1px solid #fff;
  }
}
